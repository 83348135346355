<template>
    <div class="wit-billing-invoices">
        <wit-table
            :items="localInvoices"
            :fields="columns"
            striped
            hide-per-page-selector
            ref="invoicesTable"
            :busy="busy"
        >
            <template v-slot:cell(periodStart)="{item}">
                {{ item.periodStart.toLocaleDateString() }}
            </template>
            <template v-slot:cell(periodEnd)="{item}">
                {{ item.periodEnd.toLocaleDateString() }}
            </template>
            <template v-slot:cell(actions)="{item}">
                <div class="button-row">
                    <a
                        class="invoice-resource"
                        @click="openInvoice(item, false)"
                        v-b-tooltip.hover
                        title="Open Invoice"
                    >
                        <i class="fa fa-external-link-square pointer-action"> </i
                    ></a>
                    <a
                        class="invoice-pdf-resource"
                        @click="openInvoice(item, true)"
                        v-b-tooltip.hover
                        title="Download Invoice"
                    >
                        <i class="fa fa-download pointer-action"> </i
                    ></a>
                </div>
            </template>
        </wit-table>
        <b-row align-h="center">
            <span v-b-tooltip.hover title="Retrieve more" v-if="hasMore">
                <i @click="retrieveMore" class="fa fa-angle-double-down pointer-action retrieve-more-action" />
            </span>
            <span v-b-tooltip.hover title="No more invoices" v-else>
                <i class="fa fa-angle-double-down retrieve-more-action disabled-retrieve-more-action" />
            </span>
        </b-row>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Loading from '@/components/loading.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    data() {
        return {
            columns: ['periodStart', 'periodEnd', 'actions'],
            nextPage: null,
            hasMore: true,
            busy: false,
            localInvoices: [],
        }
    },
    props: {
        invoices: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Loading,
        DetailsAction,
        WitTable,
    },
    notifications: {
        invoiceNotFound: {
            type: VueNotifications.types.error,
            title: 'The invoice could not be downloaded',
        },
    },
    computed: {
        ...mapGetters({
            billingAccounts: 'billing/accounts',
        }),
        activeBillingAccount: {
            get() {
                return this.$store.state.billing.active
            },
        },
    },
    methods: {
        async openInvoice(stripeInvoice, download) {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/wfirma/invoice/getByStripeInvoiceId/${stripeInvoice.id}`
            )
            const wFirmaInvoice = response.data.data
            if (wFirmaInvoice) {
                const downloadParam = download ? '?download=true' : ''
                window.open(
                    `${process.env.VUE_APP_NODE_API_HOST}/wfirma/invoice/download/${wFirmaInvoice.id}${downloadParam}`,
                    '_blank'
                )
            } else if (stripeInvoice.url) {
                window.open(stripeInvoice.url, '_blank')
            } else {
                this.invoiceNotFound()
            }
        },

        async retrieveMore() {
            try {
                this.busy = true
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/searchInvoices`,
                    {
                        params: {
                            billingAccountId: this.activeBillingAccount.id,
                            limit: 10,
                            page: this.nextPage,
                        },
                    }
                )

                this.localInvoices.push(
                    ...response.data.data.map(el => ({
                        id: el.id,
                        periodStart: new Date(el.period_start * 1000),
                        periodEnd: new Date(el.period_end * 1000),
                        url: el.hosted_invoice_url,
                        pdf: el.invoice_pdf,
                    }))
                )

                this.hasMore = response.data.metadata.hasMore
                this.nextPage = response.data.metadata.nextPage
                this.busy = false
                this.$emit('update:invoices', this.localInvoices)
                this.$emit('update:loading', false)
            } catch {
                this.busy = false
                this.localInvoices = []
                this.$emit('update:loading', false)
            }
        },

        onBillingAccountChange() {
            this.localInvoices = []
            this.hasMore = true
            this.nextPage = null
            this.retrieveMore()
        },
    },
    created() {
        if (this.activeBillingAccount) {
            this.retrieveMore()
        }
    },
}
</script>

<style lang="scss">
.invoice-resource:hover {
    text-decoration: none;
    color: #4b4b4c;
}
</style>
